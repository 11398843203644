import Loadable from "react-loadable";
import React from "react";
import api from '../../services/api'
import Swal from 'sweetalert2';

export const addTab = (component) => {

  const LoadableComponent = Loadable({
    loader: () => component.loader,
    loading: () => <div>Carregando...</div>,
    render(loaded, props) {
      let Component = loaded.default;
      return <Component {...props}/>;
    }
  });

  const newTabs = {title: component.title,
    content: <LoadableComponent {...component.props} />,
    key: component.key};
  return {
    type: 'add_tabs',
    payload: {tabs: newTabs, activeIndex: newTabs.length - 1}
  }
}

export const modificaTabs = (tabsInfo) => {
  return {
    type: 'modifica_tabs',
    payload: tabsInfo
  }
}

export const removeTab = (tabsInfo) => {
    return {
        type: 'remove_tabs',
        payload: tabsInfo
    }
}

export function addTabWithPermission(component){

  if(false){
    addTab(component)
  }
  else{
    Swal.fire({
      title: 'Aba sendo editada por outro usuário. Deseja assumir a edição?',
      text: "Se você assumir a pessoa que está editando perderá as alterações.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3a9d5d',
      cancelButtonColor: '#acb5bc',
      confirmButtonText: 'SIM',
      cancelButtonText: 'NÃO'

    }).then((result) => {
      if (result.value) {

      }else{

      }
    })
  }

}
