import moment from 'moment'
/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredClientesRelatorio:{
        clientes: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFiltersClientesRelatorio: {
        codigo: '',
        nome: '',
        nome_fantasia: '',
        estado: '',
        tipo_cliente: '',
        status: '',
        data_inicial: '',
        data_final: '',
    }
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_FILTERED_CLIENTE_RELATORIO':
            return {...state, filteredClientesRelatorio: action.payload}
        case 'SET_PRELOADS_FILTER_CLIENTE_RELATORIO':
            let payload = action.payload
            // payload.data_inicial = moment(payload.data_inicial).format('DD/MM/YYYY')
            // payload.data_final = moment(payload.data_final).format('DD/MM/YYYY')
            return {...state, preloadFiltersClientesRelatorio: action.payload}
        default: return state;
    }
}