/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredSaldoClienteRelatorio:{
        saldos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFiltersSaldoClienteRelatorio: {
        codigo:'',
        nome:'',
        data_inicial: '',
        data_final: '',
    }
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_FILTERED_SALDO_CLIENTE_RELATORIO':
            return {...state, filteredSaldoClienteRelatorio: action.payload}
        case 'SET_PRELOADS_FILTER_SALDO_CLIENTE_RELATORIO':
            return {...state, preloadFiltersSaldoClienteRelatorio: action.payload}
        default: return state;
    }
}
