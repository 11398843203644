import moment from 'moment'
/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredProdutosRelatorio:{
        produtos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFiltersProdutosRelatorio: {
        sku: '',
        status: '',
        data_lancamento_inicial: '',
        data_lancamento_final: '',
        data_prova_inicial: '',
        data_prova_final: '',
        editora:''
    }
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_FILTERED_PRODUTO_RELATORIO':
            return {...state, filteredProdutosRelatorio: action.payload}
        case 'SET_PRELOADS_FILTER_PRODUTO_RELATORIO':
            let payload = action.payload
            payload.data_inicial = moment(payload.data_inicial).format('DD/MM/YYYY')
            payload.data_final = moment(payload.data_final).format('DD/MM/YYYY')
            return {...state, preloadFiltersProdutosRelatorio: action.payload}
        default: return state;
    }
}