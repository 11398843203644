import moment from 'moment'
/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredTitulos:{
        titulos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFilters: { 
        pedido_codigo: '',
        fatura_codigo: '',
        nome:'',
        documento:'',
        email:'',
        enum_status:'',
        data_inicial: moment().startOf('month').format('DD-MM-YYYY'),
        data_final: moment().endOf('month').format('DD-MM-YYYY'),
    }
}
export default  (state = INITIAL_STATE, action) => {

    switch(action.type){
        case 'SET_FILTERED_TITULOS':
            return {...state, filteredTitulos: action.payload}
        case 'SET_PRELOADS_FILTER':
            return {...state, preloadFilters: action.payload}
            
        default: return state;
    }
}