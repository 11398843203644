import _ from 'lodash'
const INITIAL_STATE = {
  clientForm: [{"id": 0, "data": {
    status:'',
    documento:'',
    rg:'',
    email_faturamento:'',
    logradouro : '',
    estado: '',
    bairro: '',
    cidade: '',
    cep:'',
    numero:'',
    complemento:'',
    referencia:'',
    forma_pagamento: '',
    inscricao_estadual: '',
    nome_fantasia: '',
    nome_responsavel: '',
    prazo_fatura: '',
    ramal: '',
    razao_social: '',
    telefone1: '',
    telefone2: '',
    tipo_cliente: '',
    tipo_documento: 0,
    tipo_entidade: 0,
    transportadoras_id: [],
    porcentagem_desconto:'',
    porcentagem_devolucao:'',
    enderecos:[],
    errors:{},
    endereco_entrega_atual:{},
    isLoading: false,
    intermediador_id: '',
    selected_transportadoras:{},
    mensagem_status: '',

  }}],
  transportadoras:[],
  selected_transportadoras:[],
  isLoadingTransportadoras: false,
  clientes:{
    clientes: [],
    total: 0,
    pagina: 1,
    ultimaPagina:1,
  },
  preLoadFilterCliente:{
    codigo: '',
    nome: '',
    documento: '',
    email: '',
    status: ''
  },
  enderecos_entrega: [],
  enderecos_entrega_loading_status:[],
  intermediadorForm: [{"id": 0, "data": {
    status:'',
    documento:'',
    rg:'',
    email_faturamento:'',
    logradouro : '',
    estado: '',
    bairro: '',
    cidade: '',
    cep:'',
    numero:'',
    complemento:'',
    referencia:'',
    inscricao_estadual: '',
    nome_fantasia: '',
    nome_responsavel: '',
    ramal: '',
    razao_social: '',
    telefone1: '',
    telefone2: '',
    tipo_cliente: '',
    tipo_documento: 0,
    tipo_entidade: 0,
    errors:{},
    isLoading: false,
    mensagem_status: '',
}}],
  intermediadores:{
      intermediadores: [],
      total: 0,
      pagina: 1,
      ultimaPagina:1,
  },
  preLoadFilterIntermediador:{
      codigo: '',
      nome: '',
      documento: '',
      email: '',
      status: ''
  },
  clients_select:{},
  intermediador_select:{},
};

export default  (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'INPUT_VALUE':
      let copia = state[action.state_title].slice();
      let id = !action.dataId ? 0 : action.dataId
      let entity = copia.find(entity => entity.id == id)
      if(typeof entity !== 'undefined')
        entity.data[action.name] = action.val;
      return {...state, [action.state_title]: copia};
    case 'UPDATE_ENDERECO':
        let copiaEndereco = state[action.state_title].slice();
        let idEndereco = !action.dataId ? 0 : action.dataId
        let clienteEndereco = copiaEndereco.find(cliente => cliente.id === idEndereco)
        clienteEndereco.data['logradouro'] = action.endereco.logradouro;
        clienteEndereco.data['estado'] = action.endereco.estado;
        clienteEndereco.data['bairro'] = action.endereco.bairro;
        clienteEndereco.data['cidade'] = action.endereco.cidade;
        clienteEndereco.data['isLoading'] = false;
    return { ...state, [action.state_title]: copiaEndereco};
    case 'SET_TRANSPORTADORAS':
      return { ...state, transportadoras:action.transportadoras, isLoadingTransportadoras: false};
    case 'SET_CLIENTES':
      return { ...state, clientes: action.payload};
    case 'SET_ERROR':

         copia = state[action.state_title].slice();
         id = !action.dataId ? 0 : action.dataId
         entity = copia.find(entity => entity.id == id)
        if(typeof entity !== 'undefined')
          entity.data['errors'] = action.errors;
        return {...state, [action.state_title]: copia};
    case 'CREATE_CLIENT':
          let copiaCreate = state['clientForm'].slice();
          let idCreate = !action.dataId ? 0 : action.dataId
          let clienteCreate = copiaCreate.find(cliente => cliente.id === idCreate)
          let enderecos_entrega = state['enderecos_entrega'].slice();
          if(idCreate === 0){
            copiaCreate = copiaCreate.filter(c => c.id !==0)
            copiaCreate.push(_resetClient)
            enderecos_entrega = enderecos_entrega.filter(endereco => endereco.client_id != 0)
          }

          return {...state, clientForm: copiaCreate, enderecos_entrega: enderecos_entrega};
    case 'CREATE_EDIT_ADDRESS':
      let copiaEnderecoEntrega = state['enderecos_entrega'].slice();
      id = !action.dataId ? 0 : action.dataId
      entity = copiaEnderecoEntrega.find(entity => entity.id === id)

      if(typeof entity === 'undefined')
        copiaEnderecoEntrega.push({
          data: action.endereco,
          saved:false,
          client_id: action.client_id,
          id: action.dataId
      })
      return {...state, enderecos_entrega: copiaEnderecoEntrega};
    case 'SAVE_ADDRESS':
      copiaEnderecoEntrega = state['enderecos_entrega'].slice();
      id = !action.dataId ? 0 : action.dataId
      entity = copiaEnderecoEntrega.find(entity => entity.id === id)
      if(typeof entity !== 'undefined'){
        entity.saved = true
        if(typeof action.addressReturn !== 'undefined' && action.addressReturn !== null){
          entity.id = action.addressReturn.id
          entity.data.id = action.addressReturn.id
        }
      }

    return {...state, enderecos_entrega: copiaEnderecoEntrega};
    case 'UPDATE_ENDERECO_ENTREGA':
      copiaEnderecoEntrega = state['enderecos_entrega'].slice();
      id = !action.id ? 0 : action.id
      entity = copiaEnderecoEntrega.find(entity => entity.id === id)
      if(typeof entity !== 'undefined'){

        if(typeof action.endereco.logradouro !== 'undefined')
          entity.data.logradouro = action.endereco.logradouro

        if(typeof action.endereco.cidade !== 'undefined')
          entity.data.cidade = action.endereco.cidade

        if(typeof action.endereco.estado !== 'undefined')
          entity.data.estado = action.endereco.estado

        if(typeof action.endereco.bairro !== 'undefined')
          entity.data.bairro = action.endereco.bairro

        if(typeof action.endereco.numero !== 'undefined')
          entity.data.numero = action.endereco.numero

        if(typeof action.endereco.complemento !== 'undefined')
          entity.data.complemento = action.endereco.complemento

        if(typeof action.endereco.referencia !== 'undefined')
          entity.data.referencia = action.endereco.referencia

        if(typeof action.endereco.telefone1 !== 'undefined')
          entity.data.telefone1 = action.endereco.telefone1

        if(typeof action.endereco.telefone2 !== 'undefined')
          entity.data.telefone2 = action.endereco.telefone2

        if(typeof action.endereco.ramal !== 'undefined')
          entity.data.ramal = action.endereco.ramal

        if(typeof action.endereco.cep !== 'undefined')
          entity.data.cep = action.endereco.cep

        if(typeof action.endereco.nome_destinatario !== 'undefined')
          entity.data.nome_destinatario = action.endereco.nome_destinatario

        if(typeof action.endereco.identificacao !== 'undefined')
          entity.data.identificacao = action.endereco.identificacao

        if(typeof action.endereco.telefone !== 'undefined')
          entity.data.telefone = action.endereco.telefone

        if(typeof action.endereco.celular !== 'undefined')
          entity.data.celular = action.endereco.celular
      }

      return {...state, enderecos_entrega: copiaEnderecoEntrega};
    case 'EDITAR_CLIENTE':

      const arrayClientes = state['clientForm'].slice()
      const client = arrayClientes.find(cliente => cliente.id === action.payload.id)
      action.payload.data['errors'] = {}
      let address = action.payload.data.enderecos;
      // adiciona os enderecos na variavel que o form puxa
      let copiaEnderecoEntregaEditar = state['enderecos_entrega'].slice();
      address.forEach(function(edr) {
        id = !edr.id ? 0 : edr.id
        entity = copiaEnderecoEntregaEditar.find(entity => entity.id === id)

        if(typeof entity === 'undefined')
          copiaEnderecoEntregaEditar.push({
          data: edr,
          saved:true,
          client_id: action.payload.id,
          id: edr.id
        })
      });

      if(typeof client === 'undefined') {
        action.payload.data.select2_data = action.payload.data.intermediador
        arrayClientes.push(action.payload)
      }

      return {...state, clientForm: arrayClientes, enderecos_entrega: copiaEnderecoEntregaEditar};

      case 'DELETE_ADDRESS':
        copiaEnderecoEntrega = state['enderecos_entrega'].filter(function(endereco){
            return endereco.id !== action.id;
        });
        return {...state, enderecos_entrega: copiaEnderecoEntrega};
      case 'CREATE_INTERMEDIADOR':
          let copiaCreateI = state['intermediadorForm'].slice();
          let idCreateI = !action.dataId ? 0 : action.dataId
          let intermediadorCreate = copiaCreateI.find(intermediador => intermediador.id === idCreateI)
          if(idCreateI === 0 )
              intermediadorCreate.data = _resetClientIntermediador

      return {...state, intermediadorForm: copiaCreateI};

      case 'EDITAR_INTERMEDIADOR':
          const arrayIntermediadores = state['intermediadorForm'].slice()
          const intermediador = arrayIntermediadores.find(intermediador => intermediador.id === action.payload.id)
          if(typeof intermediador === 'undefined'){
              action.payload.data['errors'] = {}
              arrayIntermediadores.push(action.payload)
          }
       return {...state, intermediadorForm: arrayIntermediadores};
    case 'SET_INTERMEDIADORES':
        return { ...state, intermediadores: action.payload};
    case 'SET_CLIENT_SELECT':
      return {...state, clients_select: action.clientes}
    case 'LOAD_ADDRESS':
        copiaEnderecoEntrega = state['enderecos_entrega'].slice();
        action.enderecos.forEach(function(item){
          id = item.id
          entity = copiaEnderecoEntrega.find(entity => entity.id === id)
          if(typeof entity === 'undefined')
            copiaEnderecoEntrega.push({
              data: item,
              saved:true,
              client_id: item.cliente_id,
              id: id
          })
        });
        return {...state, enderecos_entrega: copiaEnderecoEntrega};
    case 'SET_INTERMEDIADOR_SELECT':
      return {...state, intermediador_select: action.intermediadores}
    case 'SET_PRELOADS_FILTER_CLIENTE':
      return {...state, preLoadFilterCliente: action.payload}
    case 'SET_PRELOADS_FILTER_INTERMEDIADOR':
      return {...state, preLoadFilterIntermediador: action.payload}
    case 'SET_ISLOADING_TRANSPORTADORAS':
      return {...state, isLoadingTransportadoras: true}
    case 'SET_ISLOADING_ENDERECOS':
      let enderecos_entrega_loading_status1 = state.enderecos_entrega_loading_status.slice()
      let enderecos_entrega_loading = enderecos_entrega_loading_status1.find(endereco => endereco.id === action.id)

      if(typeof enderecos_entrega_loading === 'undefined'){
        enderecos_entrega_loading_status1.push({id: action.id, isLoading:false})
      }else{
        enderecos_entrega_loading['id'] = action.id
        enderecos_entrega_loading['isLoading'] = action.isLoading
      }

      return {...state, enderecos_entrega_loading_status: enderecos_entrega_loading_status1}
      case 'SET_SELECTED_TRANSPORTADORAS':

      return {...state, selected_transportadoras: action.transportadoras}

      default:
      return state;
  }
}

const _resetClient = {
  status:'',
  documento:'',
  rg: '',
  email_faturamento:'',
  logradouro : '',
  estado: '',
  bairro: '',
  cidade: '',
  cep:'',
  numero:'',
  complemento:'',
  referencia:'',
  forma_pagamento: '',
  inscricao_estadual: '',
  nome_fantasia: '',
  nome_responsavel: '',
  prazo_fatura: '',
  ramal: '',
  razao_social: '',
  telefone1: '',
  telefone2: '',
  tipo_cliente: '',
  tipo_documento: 0,
  tipo_entidade: 0,
  transportadoras_id: [],
  porcentagem_desconto:'',
  porcentagem_devolucao:'',
  enderecos:[],
  errors:{},
  endereco_entrega_atual:{},
  mensagem_status: '',
};

const _resetClientIntermediador = {
    status:'',
    documento:'',
    rg:'',
    email_faturamento:'',
    logradouro : '',
    estado: '',
    bairro: '',
    cidade: '',
    cep:'',
    numero:'',
    complemento:'',
    referencia:'',
    inscricao_estadual: '',
    nome_fantasia: '',
    nome_responsavel: '',
    ramal: '',
    razao_social: '',
    telefone1: '',
    telefone2: '',
    tipo_cliente: '',
    tipo_documento: 0,
    tipo_entidade: 0,
    errors:{},
    mensagem_status: '',
};
