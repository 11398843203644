/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredContasReceberRelatorio:{
        titulos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFiltersContasReceberRelatorio: {
        nome: '',
        data_inicial_emissao: '',
        data_final_emissao: '',
        data_inicial_vencimento: '',
        data_final_vencimento: '',
        data_inicial_recebimento: '',
        data_final_recebimento: '',
        status:''
    }
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_FILTERED_CONTASRECEBER_RELATORIO':
            return {...state, filteredContasReceberRelatorio: action.payload}
        case 'SET_PRELOADS_FILTER_CONTASRECEBER_RELATORIO':
            return {...state, preloadFiltersContasReceberRelatorio: action.payload}
        default: return state;
    }
}