export const TOKEN_KEY = "@erpgrafica-Token";
export const NAME = "@erpgrafica-Name";
export const PERFIL = "@erpgrafica-Perfil";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getName = () => localStorage.getItem(NAME);
export const getPerfil = () => localStorage.getItem(PERFIL);

export const login = (token, name, perfil) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(NAME, name);
    localStorage.setItem(PERFIL, perfil);
};

export const logout = () => { localStorage.removeItem(TOKEN_KEY); window.location = '/login';};

export const checkLogout401 = (error) => {
  if(error.toString().includes('code 401')) {
    logout();
  }
};


