/* eslint-disable default-case */
import moment from 'moment'

const baseFormPedido = [
    {
        continuar: false,
        aprovar: false,
        selectedTransportadora: null,
        selectedClient:'',
        tipo_envio: 0,
        transportadora_id:'',
        endereco_id:'',
        cliente_id:'',
        status: 0,
        itens:[],
        id: 0,
        valorTotal:0
    }
]

const INITIAL_STATE = {
    filteredPedidos:{
        pedidos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFilters: {
        id:'',
        nome:'',
        documento:'',
        email:'',
        status:'',
        data_inicial: moment().startOf('month').format('DD-MM-YYYY'),
        data_final: moment().endOf('month').format('DD-MM-YYYY'),
        sku: '',
    },
    preloadFiltersAprovado: {
        id:'',
        nome:'',
        documento:'',
        email:'',
        status:1,//somente esse status(revisao) para o gerente aprovar
        data_inicial: moment().startOf('month').format('DD-MM-YYYY'),
        data_final: moment().endOf('month').format('DD-MM-YYYY'),
        sku: '',
    },
    formPedido: baseFormPedido
}

export default  (state = INITIAL_STATE, action) => {
    let formPedido = state.formPedido.slice()
    switch(action.type){
        case 'SET_FILTERED_PEDIDOS':
            return {...state, filteredPedidos: action.payload}

        case 'SET_PRELOADS_FILTER':
            return {...state, preloadFilters: action.payload}

        case 'SET_PRELOADS_FILTER_APROVADO':
            return {...state, preloadFiltersAprovado: action.payload}

        case 'UPDATE_PEDIDO':
            let pedidoUpdate = formPedido.find(pedido => pedido.id === action.pedido.id)
            if(typeof pedidoUpdate !== 'undefined'){
                let index = formPedido.indexOf(pedidoUpdate)
                formPedido[index] = action.pedido
            }
            else{
                formPedido.push(action.pedido)
            }

        return {...state, formPedido: formPedido}

        case 'RESET_CADASTRO_STATE':
            formPedido[0] = baseFormPedido

        return {...state, formPedido: formPedido}

        case 'SET_ITENS_PEDIDO':
            let pedido = formPedido.find(pedido => pedido.id === action.data.pedido_id)
            pedido.itens = action.data.itens

            if(typeof pedido !== 'undefined'){
                let index = formPedido.indexOf(pedido)
                formPedido[index] = pedido
            }
        return {...state, formPedido: formPedido}

        default: return state;
    }
}

