import moment from 'moment'
/* eslint-disable default-case */
const INITIAL_STATE = {
    clienteLancamento: {},
    selectedCliente:{},
    filteredLancamentosAprovar:{
        lancamentos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFiltersAprovar: {
        data:'',
        tipo:'',
    },
    formLancamento:[{
        id:0,
        saldo_total: 0,
        clienteLancamento: undefined,
        ultimosLancamentos: [{
            valor: 0,
            tipo: 0,
            data: moment(),
            status: 0,
        },],
        pesquisouCliente: false
    }]
}
export default  (state = INITIAL_STATE, action) => {
    let formLancamento = state.formLancamento.slice()
    switch(action.type){
        case 'SET_FILTERED_LANCAMENTOS_APROVAR':
            return {...state, filteredLancamentosAprovar: action.payload}
        case 'SET_CLIENTE_LANCAMENTO':
            return {...state, clienteLancamento: action.payload}
        case 'SET_SELECTED_CLIENTE':
            return {...state, selectedCliente:action.payload}
        case 'ADD_LANCAMENTO':
            let cliente = state.selectedCliente
            cliente.lancamentos.push(action.payload)
            return {...state, selectedCliente:cliente}
        default: return state;
    }
}
