import React from 'react';

const ClientRegister = React.lazy(() => import('./containers/Clientes/index'));
const ClientSearch = React.lazy(() => import('./containers/Clientes/pesquisar'));


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/cliente/cadastro', exact: true, name: 'Cadastro cliente', component: ClientRegister },
  { path: '/cliente/pesquisa', exact: true, name: 'Cadastro cliente', component: ClientSearch },

];

export default routes;
