/* eslint-disable default-case */
import moment from 'moment'

const baseFormOrdem = [
    {
        continuar: false,
        selectedClient:'',
        tipo_envio: 0,
        cliente_id:'',
        status: 0,
        itens:[],
        id: 0,
        valorTotal:0
    }
]

const INITIAL_STATE = {
    filteredOrdem:{
        ordem: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
        idUnico:0,
    },
    preloadFilters: {
        codigo:'',
        sku:'',
        documento:'',
        cliente:'',
        data_inicial: moment().startOf('month').format('DD-MM-YYYY'),
        data_final: moment().endOf('month').format('DD-MM-YYYY'),
        status:'',
    },
    formOrdem: baseFormOrdem,
    formChecklist:[{
        itens:[]
    }]
}

export default  (state = INITIAL_STATE, action) => {
    let formOrdem = state.formOrdem.slice()
    let formChecklist = state.formChecklist.slice()

    switch(action.type){
        case 'SET_FILTERED_ORDEM':
            return {...state, filteredOrdem: action.payload}
        case 'SET_PRELOADS_FILTER':
            return {...state, preloadFilters: action.payload}

        case 'SET_PRELOADS_FILTER_APROVADO':
            return {...state, preloadFiltersAprovado: action.payload}

        case 'UPDATE_ORDEM':
            let ordemUpdate = formOrdem.find(ordem => ordem.id === action.ordem.id)
            if(typeof ordemUpdate !== 'undefined'){
                let index = formOrdem.indexOf(ordemUpdate)
                formOrdem[index] = action.ordem
            }
            else{
                formOrdem.push(action.ordem)
            }

        return {...state, formOrdem: formOrdem}

        case 'UPDATE_CHECKLIST':
            let checklistUpdate = formChecklist.find(checklist => checklist.id === action.checklist.id)
            if(typeof checklistUpdate !== 'undefined'){
                let index = formChecklist.indexOf(checklistUpdate)
                formChecklist[index] = action.checklist
            }
            else{
                formChecklist.push(action.checklist)
            }

        return {...state, formChecklist: formChecklist}

        case 'RESET_CADASTRO_STATE':
            formOrdem[0] = baseFormOrdem
        return {...state, formOrdem: formOrdem}

        default: return state;
    }
}

