import moment from 'moment'
import _ from 'lodash'
/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredVendasPedidoRelatorio:{
        pedidos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFiltersVendasPedidoRelatorio: {
        pedido_id: '',
        fatura_id: '',
        nome: '',
        data_inicial: '',
        data_final: '',
    }
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_FILTERED_VENDASPEDIDO_RELATORIO':
            return {...state, filteredVendasPedidoRelatorio: action.payload}
        case 'SET_PRELOADS_FILTER_VENDASPEDIDO_RELATORIO':
            let payload = action.payload
            // if(!_.isEmpty(payload.data_inicial))
            //     payload.data_inicial = moment(payload.data_inicial).format('DD/MM/YYYY')
            // if(!_.isEmpty(payload.data_final))
            //     payload.data_final = moment(payload.data_final).format('DD/MM/YYYY')
            return {...state, preloadFiltersVendasPedidoRelatorio: action.payload}
        default: return state;
    }
}