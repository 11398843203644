/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredProdutos:{
        titulos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFilters: {
        codigo:'',
        nome:'',
        sku:'',
    },
    formProduto:[{
        id: 0,
        nome: '',
        tipo:'',
        paginas:'',
        sku: '',
        preco: '',
        preco_promocional: '',
        data_cadastro_site: '',
        status: '',
        capa: '',
        edicao: '',
        updated_at: '',
        errors: {},
        isLoading:false
    }],
    formAdicionarDesconto:[]
}
export default  (state = INITIAL_STATE, action) => {
    let formProduto = state.formProduto
    let formAdicionarDescontoCopia = state.formAdicionarDesconto.slice()
    let produtoDesconto = {}
    switch(action.type){
        case 'SET_FILTERED_PRODUTOS':
            return {...state, filteredProdutos: action.payload}
        case 'SET_PRELOADS_FILTER':
            return {...state, preloadFilters: action.payload}
        case 'UPDATE_PRODUTO':
        let produtoUpdate = formProduto.find(produto => produto.id === action.produto.id)
        if(typeof produtoUpdate !== 'undefined'){
            let index = formProduto.indexOf(produtoUpdate)
            formProduto[index] = action.produto
        }
        else
            formProduto.push(action.produto)
        return {...state, formProduto: formProduto}
        case 'SET_FILTERED_CLIENTES_PRODUTO':
            produtoDesconto = formAdicionarDescontoCopia.find(produto => produto.produto_id === action.produto_id)
            if(typeof produtoDesconto !== 'undefined'){
                produtoDesconto.filteredClientes = action.payload
            }
            else
            {
                if(typeof action.produto_id !== 'undefined'){
                    produtoDesconto = {
                        produto_id: action.produto_id,
                        filteredClientes: action.payload,
                        preloadFilters: {
                            codigo:'',
                            nome:'',
                            documento:'',
                        }
                    }
                    formAdicionarDescontoCopia.push(produtoDesconto)
                }
            }
            return {...state, formAdicionarDesconto: formAdicionarDescontoCopia}
        case 'SET_PRELOADS_FILTER_CLIENTE_PRODUTO':
            produtoDesconto = formAdicionarDescontoCopia.find(produto => produto.produto_id === action.produto_id)

            if(typeof produtoDesconto !== 'undefined')
                produtoDesconto.preloadFilters = action.payload
            return {...state, formAdicionarDesconto: formAdicionarDescontoCopia}
        default: return state;
    }
}
