import moment from 'moment'
/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredNotas:{
        notas: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    clienteNotaFiscal: {},
    pedidosNotaFiscal: {},
    preloadFilters: {
        codigo:'',
        pedido_codigo: '',
        nome:'',
        documento:'',
        email:'',
        status:'',
        data_inicial: moment().startOf('month').format('DD-MM-YYYY'),
        data_final: moment().endOf('month').format('DD-MM-YYYY'),
    },
    formNotaFiscal:[{
        id:0,
        valor_total: 0,
        valor_final: 0,
        clienteNotaFiscal: undefined,
        pedidosNotaFiscal: undefined,
        pedidosFaturaNotaFiscal: undefined,
        documento:'',
        codigo:'',
        pesquisouCliente: false,
        editando: false,
        enum_status: 0,
        filteredClienteNotaFiscal:{
            clientes: [],
            total: 0,
            pagina: 1,
            ultimaPagina:1,
        },
        preLoads:{
            codigo:'',
            nome:'',
            documento:'',
            email_faturamento:'',
            status:''
        },
        continuar: false,
        naturezaOperacao: '',
        peso: 0,
        qtd_caixa: 0
    }]
}

export default  (state = INITIAL_STATE, action) => {
    let formNotaFiscal = state.formNotaFiscal.slice()
    let fatura = {}
    switch(action.type){
        case 'SET_FILTERED_FATURAS':
            return {...state, filteredNotas: action.payload}
        case 'SET_CLIENTE_FATURA':
            return {...state, clienteNotaFiscal: action.payload}
        case 'SET_PEDIDOS_FATURA':
            return {...state, pedidosNotaFiscal: action.payload}
        case 'SET_PRELOADS_FILTER':
            return {...state, preloadFilters: action.payload}
        case 'UPDATE_FATURA':
            let faturaUpdate = formNotaFiscal.find(fatura => fatura.id === action.fatura.id)
            if(typeof faturaUpdate !== 'undefined'){
                let index = formNotaFiscal.indexOf(faturaUpdate)
                formNotaFiscal[index] = action.fatura
            }
            else
                formNotaFiscal.push(action.fatura)
            return {...state, formNotaFiscal: formNotaFiscal}
        case'SET_FILTERED_CLIENTES':
            fatura = formNotaFiscal.find(f => f.id === action.fatura_id)
            fatura.filteredClienteNotaFiscal = action.payload
        return {...state, formNotaFiscal: formNotaFiscal}
        case'SET_PRE_LOADS_CLIENTES':
            fatura = formNotaFiscal.find(f => f.id === action.fatura_id)
            fatura.preLoads = action.payload
        return {...state, formNotaFiscal: formNotaFiscal}
        default: return state;
    }
}