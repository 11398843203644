import moment from 'moment'
/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredFaturas:{
        faturas: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    clienteFatura: {},
    pedidosFatura: {},
    preloadFilters: {
        codigo:'',
        pedido_codigo: '',
        nome:'',
        documento:'',
        email:'',
        status:'',
        data_inicial: moment().startOf('month').format('DD-MM-YYYY'),
        data_final: moment().endOf('month').format('DD-MM-YYYY'),
        sku: '',
        ids: '',
    },
    formFatura:[{
        id:0,
        valor_total: 0,
        valor_final: 0,
        abatimento_credito: 0,
        saldo_total: 0,
        clienteFatura: undefined,
        pedidosFatura: undefined,
        titulosFatura: [{
            valor_pago: 0,
            valor: 0,
            enum_status: 0,
            data_vencimento: moment(),
            enum_forma_pagamento: 0,
            numero: '',
            banco: '',
            nome: '',
            documento: '',
            data_cheque: '',
            data_descontar: '',
            numecidadero: '',
            estado: '',
            link_url_boleto: null,
        }],
        documento:'',
        codigo:'',
        pesquisouCliente: false,
        editando: false,
        enum_status: 0,
        data_primeira: moment(),
        parcelas: 1,
        periodo: 15,
        forma_pagamento: 0,
        filteredClienteFatura:{
            clientes: [],
            total: 0,
            pagina: 1,
            ultimaPagina:1,
        },
        preLoads:{
            codigo:'',
            nome:'',
            documento:'',
            email_faturamento:'',
            status:''
        }
    }]
}
export default  (state = INITIAL_STATE, action) => {
    let formFatura = state.formFatura.slice()
    let fatura = {}
    switch(action.type){
        case 'SET_FILTERED_FATURAS':
            return {...state, filteredFaturas: action.payload}
        case 'SET_CLIENTE_FATURA':
            return {...state, clienteFatura: action.payload}
        case 'SET_PEDIDOS_FATURA':
            return {...state, pedidosFatura: action.payload}
        case 'SET_PRELOADS_FILTER':
            return {...state, preloadFilters: action.payload}
        case 'UPDATE_FATURA':
            let faturaUpdate = formFatura.find(fatura => fatura.id === action.fatura.id)
            if(typeof faturaUpdate !== 'undefined'){
                let index = formFatura.indexOf(faturaUpdate)
                formFatura[index] = action.fatura
            }
            else
                formFatura.push(action.fatura)
            return {...state, formFatura: formFatura}
        case'SET_FILTERED_CLIENTES':
            fatura = formFatura.find(f => f.id === action.fatura_id)
            fatura.filteredClienteFatura = action.payload
        return {...state, formFatura: formFatura}
        case'SET_PRE_LOADS_CLIENTES':
            fatura = formFatura.find(f => f.id === action.fatura_id)
            fatura.preLoads = action.payload
        return {...state, formFatura: formFatura}
        default: return state;
    }
}
