import React from "react";

const INITIAL_STATE = {
  tabs: {
      tabs:[{
          content:
            <div>
              Sistema de Gestão Empresarial Nova Concursos.<br/>
              Utilize o menu lateral para navegar entre as opções disponíveis para o seu usuário.<br/>
            </div>,
          title: `Dashboard`,
          key: 'dash'
      }],
      activeIndex: 0
  }
}

export default (states = INITIAL_STATE, action) => {

  if(action.type === 'add_tabs') {
      let getIndex = states.tabs.tabs.findIndex(tab => tab.key === action.payload.tabs.key);

      if(getIndex < 0)
      {
          states.tabs.tabs.push(action.payload.tabs);
          getIndex = states.tabs.tabs.length - 1;
      }

      const newTabs = states.tabs.tabs;
      const news = {tabs: newTabs, activeIndex: getIndex};
    return { tabs: news }
  }
  if(action.type === 'modifica_tabs') {
    return { ...states, tabs: action.payload }
  }
  if(action.type === 'remove_tabs') {
      let getIndex = states.tabs.tabs.findIndex(tab => tab.key === action.payload.key);
      states.tabs.tabs.splice(getIndex, 1);
      const newTabs = states.tabs.tabs;
      const news = {tabs: newTabs, activeIndex: 0};
      return { tabs: news }
  }
  return states;
}
