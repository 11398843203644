import moment from 'moment'
/* eslint-disable default-case */
const baseFormDevolucao = [
    {
        continuar: false,
        selectedClient:'',
        cliente_id:'',
        status: 0,
        itens:[],
        id: 0
    }
]

const INITIAL_STATE = {
    filteredDevolucaos:{
        devolucoes: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFilters:{
        nome: '',
        documento: '',
        data_inicial: moment().startOf('month').format('DD-MM-YYYY'),
        data_final: moment().endOf('month').format('DD-MM-YYYY'),
        codigo:''
    },
    formDevolucao: baseFormDevolucao
}

export default  (state = INITIAL_STATE, action) => {
    let formDevolucao = state.formDevolucao.slice()
    switch(action.type){
        case 'UPDATE_DEVOLUCAO':
            let devolucaoUpdate = formDevolucao.find(devolucao => devolucao.id === action.devolucao.id)
            if(typeof devolucaoUpdate !== 'undefined'){
                let index = formDevolucao.indexOf(devolucaoUpdate)
                formDevolucao[index] = action.devolucao
            }
            else{
                formDevolucao.push(action.devolucao)
            }

            return {...state, formDevolucao: formDevolucao}
        case 'SET_FILTERED_DEVOLUCOES':
            return {...state, filteredDevolucaos: action.payload}
        
        case 'SET_PRELOADS_FILTER':
            return {...state, preloadFilters: action.payload}
        
        default: return state;
    }
}