/* eslint-disable default-case */
const INITIAL_STATE = {
   configuracao:{
        limite_devolucao: ''
   }
}

export default  (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'UPDATE_REDUX':
            return {...state, configuracao: action.payload}
        default: return state;
    }
}