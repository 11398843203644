/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredNotaFiscalRelatorio:{
        notas: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFiltersNotaFiscalRelatorio: {
        data_inicial: '',
        data_final: '',
        pedido_id:'',
        fatura_id:'',
        id:'',
        natureza_operacao:'',
        nome:'',
        status: '',
    }
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_FILTERED_NOTAFISCAL_RELATORIO':
            return {...state, filteredNotaFiscalRelatorio: action.payload}
        case 'SET_PRELOADS_FILTER_NOTAFISCAL_RELATORIO':
            return {...state, preloadFiltersNotaFiscalRelatorio: action.payload}
        default: return state;
    }
}
