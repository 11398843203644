/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredTransportadoras:{
        titulos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFilters: {
        codigo:'',
        nome:'',
        documento:'',
    },
    formTransportadora:[{
        id: 0,
        nome: '',
        documento: '',
        razao_social: '',
        inscricao_estadual: '',
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        cep: '',
        telefone1:'',
        nome_responsavel: '',
        referencia:'',
        telefone2:'',
        complemento:'',
        status:1,
        horarios:[],
        errors: {},
        isLoading:false
    }]
}
export default  (state = INITIAL_STATE, action) => {
    let formTransportadora = state.formTransportadora 
    switch(action.type){
        case 'SET_FILTERED_TRANSPORTADORAS':
            return {...state, filteredTransportadoras: action.payload}
        case 'SET_PRELOADS_FILTER':
            return {...state, preloadFilters: action.payload}
        case 'UPDATE_TRANSPORTADORA':
        let transportadoraUpdate = formTransportadora.find(transportadora => transportadora.id === action.transportadora.id)
        if(typeof transportadoraUpdate !== 'undefined'){
            let index = formTransportadora.indexOf(transportadoraUpdate)
            formTransportadora[index] = action.transportadora
        }
        else
        formTransportadora.push(action.transportadora)
        return {...state, formTransportadora: formTransportadora}
        default: return state;
    }
}