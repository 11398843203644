/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredCreditoConcedidoRelatorio:{
        lancamentos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFiltersCreditoConcedidoRelatorio: {
        codigo:'',
        nome:'',
        data_inicial: '',
        data_final: '',
        status:'',
    }
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_FILTERED_CREDITO_CONCEDIDO_RELATORIO':
            return {...state, filteredCreditoConcedidoRelatorio: action.payload}
        case 'SET_PRELOADS_FILTER_CREDITO_CONCEDIDO_RELATORIO':
            return {...state, preloadFiltersCreditoConcedidoRelatorio: action.payload}
        default: return state;
    }
}
