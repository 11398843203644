/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredVendasItemRelatorio:{
        items: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFiltersVendasItemRelatorio: {
        pedido_id: '',
        sku: '',
        nome: '',
        data_inicial: '',
        data_final: '',
        editora:''
    }
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_FILTERED_VENDASITEM_RELATORIO':
            return {...state, filteredVendasItemRelatorio: action.payload}
        case 'SET_PRELOADS_FILTER_VENDASITEM_RELATORIO':
            return {...state, preloadFiltersVendasItemRelatorio: action.payload}
        default: return state;
    }
}