/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredVendasClienteRelatorio:{
        pedidos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFiltersVendasClienteRelatorio: {
        nome: '',
        data_inicial: '',
        data_final: '',
        tipo_cliente:'',
        editora:''
    }
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_FILTERED_VENDASCLIENTE_RELATORIO':
            return {...state, filteredVendasClienteRelatorio: action.payload}
        case 'SET_PRELOADS_FILTER_VENDASCLIENTE_RELATORIO':
            return {...state, preloadFiltersVendasClienteRelatorio: action.payload}
        default: return state;
    }
}