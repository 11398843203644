import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Token = `${token}`;
  }
  return config;
});

export default api;
