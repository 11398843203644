import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container } from 'reactstrap';
import {isAuthenticated} from '../../services/auth';
import { connect } from 'react-redux';
import { addTab } from '../../redux/actions/TabActions';
import {logout} from '../../services/auth'
import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';

const DefaultAside = React.lazy(() => import('./DefaultAside'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));
const DefaultTabs = React.lazy(() => import('./DefaultTab'));
const DefaultNav = React.lazy(() => import('./DefaultNav'));

class DefaultLayout extends Component {

  loading = () => <div className="animated fadeIn pt-1 text-center">Carregando...</div>

  signOut(e) {
    e.preventDefault()
    logout()
    this.props.history.push('/login')
  }

    render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense  fallback={this.loading()}>
            <DefaultHeader onLogout={e=>this.signOut(e)}/>
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
                <DefaultNav />
            </Suspense>
            <AppSidebarFooter />
          </AppSidebar>
          <main className="main">
            <div className="spaceTab"></div>
            <DefaultTabs/>
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      (isAuthenticated()) ? (<Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <route.component {...props} />
                        )} />) : (<Redirect from="/" to="/login" />)
                    ) : (null);
                  })}
                </Switch>
              </Suspense>
            </Container>
          </main>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
})
export default connect(mapStateToProps, { addTab })(DefaultLayout);
