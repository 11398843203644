import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import TabReducer from '../src/redux/reducers/TabReducer';
import CadastroReducer from '../src/redux/reducers/CadastroReducer';
import PedidoReducer from '../src/redux/reducers/PedidoReducer';
import FaturaReducer from '../src/redux/reducers/FaturaReducer';
import LancamentoReducer from '../src/redux/reducers/LancamentoReducer';
import TituloReducer from '../src/redux/reducers/TituloReducer';
import TransportadoraReducer from '../src/redux/reducers/TransportadoraReducer';
import ProdutoReducer from '../src/redux/reducers/ProdutoReducer';
import DevolucaoReducer from '../src/redux/reducers/DevolucaoReducer';
import NotaFiscalReducer from '../src/redux/reducers/NotaFiscalReducer';
import ConfiguracaoReducer from '../src/redux/reducers/ConfiguracaoReducer';
import OrdemReducer from '../src/redux/reducers/OrdemReducer';
import ClienteRelatorioReducer from '../src/redux/reducers/ClienteRelatorioReducer';
import ProdutoRelatorioReducer from '../src/redux/reducers/ProdutoRelatorioReducer';
import VendasPedidoRelatorioReducer from '../src/redux/reducers/VendasPedidoRelatorioReducer';
import VendasItemRelatorioReducer from '../src/redux/reducers/VendasItemRelatorioReducer';
import VendasClienteRelatorioReducer from '../src/redux/reducers/VendasClienteRelatorioReducer';
import FaturaRelatorioReducer from '../src/redux/reducers/FaturaRelatorioReducer';
import IndicadoresRelatorioReducer from '../src/redux/reducers/IndicadoresRelatorioReducer';
import NotaFiscalRelatorioReducer from '../src/redux/reducers/NotaFiscalRelatorioReducer';
import ContasReceberRelatorioReducer from '../src/redux/reducers/ContasReceberRelatorioReducer';
import CreditoConcedidoRelatorioReducer from "./redux/reducers/CreditoConcedidoRelatorioReducer";
import SaldoClienteRelatorioReducer from "./redux/reducers/SaldoClienteRelatorioReducer";

// import { renderRoutes } from 'react-router-config';
import './App.scss';
import DefaultLayout from './containers/DefaultLayout/DefaultLayout';

const loading = () => <div className="animated fadeIn pt-3 text-center">Carregando...</div>;

// Containers
//const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./containers/Login'));
const Page404 = React.lazy(() => import('./containers/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const rootReducer = combineReducers({
  TabReducer: TabReducer,
  CadastroReducer: CadastroReducer,
  PedidoReducer: PedidoReducer,
  FaturaReducer: FaturaReducer,
  TransportadoraReducer: TransportadoraReducer,
  TituloReducer: TituloReducer,
  ProdutoReducer: ProdutoReducer,
  LancamentoReducer: LancamentoReducer,
  NotaFiscalReducer: NotaFiscalReducer,
  ConfiguracaoReducer: ConfiguracaoReducer,
  DevolucaoReducer: DevolucaoReducer,
  OrdemReducer: OrdemReducer,
  ClienteRelatorioReducer: ClienteRelatorioReducer,
  ProdutoRelatorioReducer: ProdutoRelatorioReducer,
  VendasPedidoRelatorioReducer: VendasPedidoRelatorioReducer,
  VendasItemRelatorioReducer: VendasItemRelatorioReducer,
  VendasClienteRelatorioReducer: VendasClienteRelatorioReducer,
  FaturaRelatorioReducer: FaturaRelatorioReducer,
  IndicadoresRelatorioReducer: IndicadoresRelatorioReducer,
  NotaFiscalRelatorioReducer: NotaFiscalRelatorioReducer,
  ContasReceberRelatorioReducer: ContasReceberRelatorioReducer,
  CreditoConcedidoRelatorioReducer: CreditoConcedidoRelatorioReducer,
  SaldoClienteRelatorioReducer: SaldoClienteRelatorioReducer,

});

const store = createStore(rootReducer, {}, applyMiddleware(ReduxThunk));

class App extends Component {

  render() {
    return (
        <BrowserRouter>
            <React.Suspense fallback={loading()}>
              <Provider store={store}>
                <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                  {/* <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}
                  <Route path="/" name="Home" component={DefaultLayout} />
                </Switch>
            </Provider>
            </React.Suspense>
        </BrowserRouter>
    );
  }
}

export default App;
