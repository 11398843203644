/* eslint-disable default-case */
const INITIAL_STATE = {
    filteredIndicadoresRelatorio:{
        pedidos: [],
        total: 0,
        pagina: 1,
        ultimaPagina:1,
    },
    preloadFiltersIndicadoresRelatorio: {
        editora:'',
        data_inicial: '',
        data_final: '',
    }
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_FILTERED_INDICADORES_RELATORIO':
            return {...state, filteredIndicadoresRelatorio: action.payload}
        case 'SET_PRELOADS_FILTER_INDICADORES_RELATORIO':
            return {...state, preloadFiltersIndicadoresRelatorio: action.payload}
        default: return state;
    }
}